import { createContext } from "react";
import swal from "sweetalert";



// // ****************** Test configration start **************************

// export const apiUrl = "https://test-api.betfolio.co/api/v1"
// export const baseUrl = "https://test-api.betfolio.co/"
// export const tokenAddress = "0x5E6168E0B90Ec559CF0376c4BFDCa4a5689Ae943"; //testnet
// export const feedWalletAddress = "0x7c4db95D43854B3b1bE0ACD53e34146C9451c417";

// export const folioTokenAddress = '0x774946f2D38A5c1F98FD47ebf7cDb8b0dDd21FDb';
// export const feedTokenWallet = "0x1235f3f690337928627D0f4119206767c7FdceB1";
// export const fundTransferContract = "0x6eDeA791982D0570b325b9366999492C94b5B5Ff";
// export const fundTransferDiamondContract = "0xb2eb9453BcF0dCb86d926160Bce620f6B58257A0";
// export const fundTransferFolioContract = "0x6eDeA791982D0570b325b9366999492C94b5B5Ff";
// export const polygonRpcUrl = "https://eth-sepolia.g.alchemy.com/v2/ZbBgRPLIBdkabBTDqNGXe03I5Lh_pYmO"
// ****************** Test configration ends ***************

// ****************** staging configration start **************************
export const apiUrl = "https://betfolio-api.ecomempire.in/api/v1"
export const baseUrl = "https://betfolio-api.ecomempire.in/"
export const tokenAddress = "0x04BcEfE854Bcfee99d6921249db6F83090b5B2b8";  // staging usdc6
export const feedWalletAddress = "0x7c4db95D43854B3b1bE0ACD53e34146C9451c417";

export const folioTokenAddress = '0x85114f24b621157FFd5b4ad9CEbB60333E7E1da4';
export const feedTokenWallet = "0x1235f3f690337928627D0f4119206767c7FdceB1";
export const fundTransferContract = "0x2269bFC76c433066B893e2248222d6D82176Ecfb";
export const fundTransferDiamondContract = "0xe244652fef5bf57a95d39abd9a9b41dfe959e15f";
export const fundTransferFolioContract = "0x4103a2c36bf268dD70e04De4Da8061b9499A94CF";
export const polygonRpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/7yxaxXwiBlHGtpDrsUZzZJOJHN1dR2yf";
// ****************** staging configration ends ***************

// ****************** live configration start **************************
// export const apiUrl = "https://api.betfolio.co/api/v1";
// export const baseUrl = "https://api.betfolio.co/";
// export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";  // live
// export const feedWalletAddress = "0x9B9fc4273b1B892b54C0CEb8D805709Cd2C92a5b";
// export const folioTokenAddress = '0x2877bc2369a42634becb2d9a4dcf77d97a7230c4';
// export const feedTokenWallet = "0xc671D3387f4264C00dfE8e42403b2a09D97AaFB9";
// export const fundTransferContract = "0x8237c94A5cDFd52e411f60C960f350Af45747BF1";
// export const fundTransferDiamondContract = "0x4bfE755696D0EB90b0e85a194684417387c90E9e";
// export const fundTransferFolioContract = "0xF878335e3eaE629244bAA7C8E38dED0be40440E6";
// export const polygonRpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/7yxaxXwiBlHGtpDrsUZzZJOJHN1dR2yf"
// ****************** live configration ends ***************

export const MyContext = createContext();

export const fundWalletAddress = "0xc42F48a8E1C2721FA25fdfDBb6A7d5e256A63694";
export const USDC_DECIMALS = 6;
export const FOLIO_DECIMALS = 18;

export const Unauthorized = () => {
  swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear();
    window.location.href = "/";
    console.log("OK button clicked after error alert");
  });
};
export const dateFormate = "DD/MM/YYYY hh:mm A";
export const formatBalance = (rawBalance) => {
  const balance = (rawBalance / 1000000).toFixed(2);
  return balance;
};


export const formatNumber = (num) => {
  let formattedNumber = num.toFixed(6);
  // Remove trailing zeros and decimal point if all zeros
  formattedNumber = formattedNumber.replace(/\.?0*$/, '');
  return formattedNumber;
}


export const ITEMS_PER_PAGE = 10;

export const formatWithDecimals = (number, decimals) => {
  decimals = decimals ? decimals : 6
  const divider = 10 ** decimals;
  let balance = number / divider;
  return formatNumber(balance);
}

/* for folio update when we are avaliable */

export const formatWithDecimalsFolio = (number, decimals) => {
  decimals = decimals ? decimals : FOLIO_DECIMALS
  const divider = 10 ** decimals;
  let balance = number / divider;
  return formatNumber(balance);
}


// export const transactionurl =  "https://mumbai.polygonscan.com/tx/"
export const transactionurl = "https://polygonscan.com/tx/";

export const thresholdTimeFram = [
  { value: 2, label: "2 Mins" },
  { value: 240, label: "4 Hours" },
  { value: 360, label: "6 Hours" },
  { value: 720, label: "12 Hours" },
  { value: 1440, label: "24 Hours" },
  { value: 2880, label: "48 Hours" },
  { value: 4320, label: "72 Hours" },
  { value: 10080, label: "1 Week" },
  { value: 20160, label: "2 Weeks" },
];

export const formatWalletAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 4);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};
